<template>
    <CleverAd ref="clever"/>
    <div class="bg-blue-800 py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static" style="min-height: 84px;">
        <Link href="/">
            <img :src="appLogo"
                 :srcset="appLogoWebp + ', ' + appLogo"
                 alt="CyprusNews.Live Logo"
                 class="mr-0 lg:mr-6"
                 height="80"
            >
        </Link>
        <span v-ripple v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }" class="cursor-pointer block lg:hidden text-blue-100 mt-1 p-ripple"
              rel="nofollow">
            <i class="pi pi-bars text-4xl"></i>
        </span>
        <div class="scrollable-list align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-blue-800 left-0 top-100 z-1 shadow-2 lg:shadow-none">
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                <li>
                    <Link v-ripple :href="route('newsportal.home')"
                          class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-blue-50 hover:bg-blue-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <span>{{ __('Home') }}</span>
                    </Link>
                </li>
                <!--                <li class="hidden lg:block" v-for="newspaper in newspapers" :key="newspaper.id">-->
                <!--                    <Link v-if="newspaper.is_visible" v-ripple :href="route('newsportal.newspaper', newspaper.slug)"-->
                <!--                       class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-blue-50 hover:bg-blue-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">-->
                <!--                        <span class="font-medium">{{ newspaper.name }}</span>-->
                <!--                    </Link>-->
                <!--                </li>-->
                <li class="lg:hidden">
                    <span v-ripple
                          v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                          class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-blue-50 hover:bg-blue-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <span>{{ __('Categories') }}</span>

                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                    </span>
                    <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute lg:border-1 border-50 surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                        <li v-for="category in categories" :key="category.id">
                            <Link v-if="category.is_visible" v-ripple :href="route('newsportal.category', category.slug)"
                                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                <span class="font-medium">{{ category.name }}</span>
                            </Link>
                        </li>
                    </ul>
                </li>
                <li> <!-- class="hidden lg:block" -->
                    <span v-ripple
                          v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                          class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-blue-50 hover:bg-blue-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <span>{{ __('News Portals') }}</span>

                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                    </span>
                    <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute lg:border-1 border-50 surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                        <li v-for="newspaper in newspapers" :key="newspaper.id">
                            <Link v-if="newspaper.is_visible" v-ripple :href="route('newsportal.newspaper', newspaper.slug)"
                                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                <span class="font-medium">{{ newspaper.name }}</span>
                            </Link>
                        </li>
                    </ul>
                </li>
                <li v-if="$page.props.locale === 'el'">
                    <span v-ripple
                          v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                          class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-blue-50 hover:bg-blue-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <span>{{ __('Football') }}</span>

                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                    </span>
                    <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute lg:border-1 border-50 surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                        <li v-for="team in sportTeams" :key="team.id">
                            <Link v-ripple :href="route('newsportal.sport', team.slug)"
                                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                <span class="font-medium">{{ team.name }}</span>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li v-if="$page.props.locale === 'el'">
                    <span v-ripple
                          v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                          class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-blue-50 hover:bg-blue-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <span>{{ __('Classifieds') }}</span>

                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                    </span>
                    <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute lg:border-1 border-50 surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                        <li>
                            <Link v-ripple :href="route('newsportal.jobs')"
                                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                <span class="font-medium">{{ __('Recent Classifieds') }}</span>
                            </Link>
                        </li>

                        <li>
                            <Link v-ripple :href="route('newsportal.jobs.create')"
                                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                <span class="font-medium">{{ __('Post A Classified') }}</span>
                            </Link>
                        </li>
                    </ul>
                </li>
                <li v-if="$page.props.locale === 'el'">
                    <span v-ripple
                          v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                          class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-blue-50 hover:bg-blue-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <span>{{ __('Useful') }}</span>

                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                    </span>
                    <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute lg:border-1 border-50 surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                        <li>
                            <Link v-ripple :href="route('newsportal.pharmacy')"
                                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                <span class="font-medium">{{ __('Night Pharmacies') }}</span>
                            </Link>
                        </li>

                        <li>
                            <Link v-ripple :href="route('newsportal.history')"
                                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                <span class="font-medium">{{ __('On This Day') }}</span>
                            </Link>
                        </li>

                        <li>
                            <Link v-ripple :href="route('newsportal.flights')"
                                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                <span class="font-medium">{{ __('Airports') }}</span>
                            </Link>
                        </li>

                        <li>
                            <Link v-ripple :href="route('newsportal.valuebets')"
                                  class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                <span class="font-medium">Betting Tips</span>
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                <li>
                    <AppLocale/>
                </li>
            </ul>
        </div>
    </div>
    <div class="surface-overlay relative">
        <div id="navbar-7" class="py-2 px-2 shadow-2 hidden lg:block absolute lg:static lg:w-auto w-full surface-overlay left-0 top-100 z-1 shadow-2">
            <ul class="list-none p-0 m-0 justify-content-center flex lg:align-items-center flex-column lg:flex-row select-none">
                <li v-for="category in categories" :key="category.id">
                    <Link v-if="category.is_visible" v-ripple :href="route('newsportal.category', category.slug)"
                          class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <span class="font-medium">{{ category.name }}</span>
                    </Link>
                </li>
            </ul>
        </div>
    </div>

</template>

<script>
import FormField from "@/Components/Primitives/FormField.vue"
import appLogo from '/resources/images/cyprusnews/logotype-cy-white.png';
import appLogoWebp from '/resources/images/cyprusnews/logotype-cy-white.webp';
import {Link} from '@inertiajs/vue3'
import AppLocale from '@/Components/AppLocale.vue';
import CleverAd from '@/Components/CleverAd.vue';

export default {
    components : {
        CleverAd,
        AppLocale,
        FormField,
        Link
    },
    data() {
        return {
            toggleCategories : false,
            toggleNewspapers : false,
            appLogo,
            appLogoWebp
        };
    },
    methods  : {
        toggle(event) {
            this.$refs.profileMenu.toggle(event);
        },
    },
    computed : {
        sportTeams() {
            return this.$page.props.sportTeams;
        },
        newspapers() {
            return this.$page.props.newspapers;
        },
        categories() {
            return this.$page.props.categories;
        },
        profileItems() {
            let items = []

            if (this.$page.props?.auth.user?.roles.length > 0) {
                items.push({
                    label   : 'Dashboard',
                    icon    : 'pi pi-home',
                    command : () => {
                        this.$inertia.visit(route('dashboard.index'))
                    }
                })
            }

            items = [
                ...items,

                {
                    label   : 'Profile',
                    icon    : 'pi pi-user',
                    command : () => {
                        this.$inertia.visit(route('profile'))
                    }
                },
                {
                    label   : 'Sign out',
                    icon    : 'pi pi-sign-out',
                    command : () => {
                        this.$inertia.visit(route('logout'))
                    }
                }
            ]
            return items
        },
        signInItems() {
            let items = []
            items     = [
                {
                    label   : 'Login',
                    icon    : 'pi pi-sign-in',
                    command : () => {
                        this.$inertia.visit(route('login'))
                    }
                },
                {
                    label   : 'Register',
                    icon    : 'pi pi-user-plus',
                    command : () => {
                        this.$inertia.visit(route('register'))
                    }
                }
            ]
            return items
        },
    },
};
</script>

<style>
::placeholder {
    color   : white;
    opacity : 0.3;
}

</style>