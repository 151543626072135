<template>
<!--    <div id="ad-container" style="min-height: 300px">-->
        <!-- Ad will be loaded here -->
<!--    </div>-->
</template>

<script>
export default {
    name : 'CleverAd',
    mounted() {
//        this.loadAdScript();
//        this.checkInterval = setInterval(() => {
//            const element = document.querySelectorAll('[id^="clever-77788-"]')[0];
//            if (element) {
//                document.getElementById('ad-container').remove();
//                clearInterval(this.checkInterval);
//            }
//        }, 10);
//
//        this.checkTimeout = setTimeout(() => {
//            clearInterval(this.checkInterval);
//        }, 100);
    },
    beforeDestroy() {
//        if (this.checkInterval) {
//            clearInterval(this.checkInterval);
//        }
//        if (this.checkTimeout) {
//            clearTimeout(this.checkTimeout);
//        }
    },
    methods : {
        loadAdScript() {
            if (!this.$page.props.cleverAds) {
                document.getElementById('ad-container').remove();
                document.getElementsByClassName('clever-core-ads')[0]?.remove();
                return;
            }
            // Remove the existing script if it exists
            const existingScript = document.getElementById('CleverCoreLoader77788');
            if (existingScript) {
                existingScript.parentNode.removeChild(existingScript);
            }

            const script    = document.createElement('script');
            script.id       = 'CleverCoreLoader77788';
            // Add a unique query parameter to the script URL to prevent caching
            const timeStamp = new Date().getTime();
            script.src      = `https://scripts.cleverwebserver.com/2c7e07edaf5d5f87332ab604efa304fe.js?timestamp=${timeStamp}`;

            script.async = true;
            script.type  = 'text/javascript';

            document.body.appendChild(script); // Append the script to <body> or another specific element as needed
        },
    }
}
</script>
