<template>
    <footer>
        <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
            <div class="border-top-1 border-300 pt-5 text-center">
                <img :src="logoSrc" alt="Image" height="60" />
                <div class="font-medium text-900 mt-4 mb-3">&copy; 2024 CyprusNews.Live</div>
                <ul class="list-none p-0 mx-0 my-5 flex justify-content-center flex-column align-items-center lg:flex-row">
                    <li><a class="text-600 cursor-pointer line-height-3 lg:mr-5" href="/contact-us">Contact Us</a></li>
                    <li><a class="text-600 cursor-pointer line-height-3 lg:mr-5" href="/contact-us">Advertise Here</a></li>
                </ul>
                <div class="flex align-items-center justify-content-center mb-5">
                    <a href="https://www.facebook.com/cyprusnews.live/" class="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center mr-5" style="width:2.5rem; height: 2.5rem">
                        <i class="pi pi-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/cyprusnewslive/" class="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center mr-5" style="width:2.5rem; height: 2.5rem">
                        <i class="pi pi-instagram"></i>
                    </a>
                </div>
                <div class="text-center">
                    <a class="mr-5 text-sm text-600 cursor-pointer" href="/policy">Privacy Policy</a>
                    <a class="text-sm text-600 cursor-pointer" href="/terms">Terms of Service</a>
                </div>
            </div>
        </div>

    </footer>
</template>

<script>
import logo from '/resources/images/cyprusnews/logotype-cy-blue-no-text.png'
import Divider from 'primevue/divider';
import {Link} from "@inertiajs/vue3";

export default {
    components: {
        Link,
        Divider
    },
    data() {
        return {
            logoSrc : '',
        };
    },
    mounted() {
        this.logoSrc = logo;
    }
}
</script>

<style scoped>
.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}
</style>