<template>
    <li>
        <span v-ripple
              v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white hover:text-blue-50 hover:bg-blue-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
            <i :class="currentLang" class="mr-3 lg:mr-0" style="width: 32px; height: 32px"/>

            <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
        </span>
        <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute lg:border-1 border-50 surface-overlay hidden origin-top w-full lg:w-10rem cursor-pointer">
            <li v-for="lang in languages" :key="lang.label">
                <span v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple"
                      @click="lang.command()">
                    <i :class="lang.icon" class="mr-3 lg:mr-0" style="width: 32px; height: 32px"/> <span class="pl-2 font-medium"> {{ lang.label }}</span>
                </span>
            </li>
        </ul>
    </li>

</template>
<script>

export default {
    methods  : {
        changeLanguage(lang) {
            if (this.$page.props.locale === lang) {
                return;
            }

            const url           = new URL(window.location);
            const hostnameParts = url.hostname.split('.');

            if (lang === 'en' && hostnameParts[0] !== 'en') {
                hostnameParts.unshift('en');
            }

            if (lang === 'el' && hostnameParts[0] === 'en') {
                hostnameParts.shift();
            }

            url.hostname = hostnameParts.join('.');
            url.search   = '';
            url.pathname = '/';
            window.location.replace(url.href);
        },
        toggle(event) {
            this.$refs.languageMenu.toggle(event);
        }
    },
    computed : {
        currentLang() {
            return this.$page.props.locale === 'en' ? 'fi fi-gb' : 'fi fi-gr';
        },
        languages() {
            return [
                {
                    label   : 'English',
                    icon    : 'fi fi-gb',
                    command : () => this.changeLanguage('en'),
                },
                {
                    label   : 'Ελληνικά',
                    icon    : 'fi fi-gr',
                    command : () => this.changeLanguage('el'),
                },
            ];
        }
    }
}
</script>