<template>
    <div class="w-full">
        <div class="block font-bold text-center p-4 border-round mb-3">
            <ins class="adsbygoogle"
                 data-ad-client="ca-pub-2609724905403481"
                 data-ad-format="auto"
                 data-ad-slot="4858591030"
                 data-full-width-responsive="true"
                 style="display:block">
            </ins>
        </div>

    </div>
</template>

<script>
export default {
    mounted() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
};
</script>
